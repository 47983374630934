<script>

import { BButton, BModal } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    kind: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: 'Título do modal',
    },
    content: {
      type: String,
      default: 'Conteúdo do modal',
    },
    confirmButtonLabel: {
      type: String,
      default: null,
    },
    cancelButtonLabel: {
      type: String,
      default: null,
    },
  },
  methods: {
    confirmAction(modalEvent) {
      modalEvent.preventDefault()
      this.$emit('confirmed')
    },
    cancelAction(modalEvent) {
      modalEvent.preventDefault()
      this.$emit('canceled')
    },
  },
}
</script>

<template>
  <b-modal
    v-model="showModal"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
  >
    <div
      class="d-flex flex-column align-items-center justify-center-center my-2 px-3"
    >
      <div>
        <feather-icon
          v-if="this.$props.kind.toUpperCase() === 'SUCCESS'"
          icon="CheckCircleIcon"
          class="CheckCircleIcon"
          size="64"
          style="color: #28C76F"
        />
        <feather-icon
          v-if="this.$props.kind.toUpperCase() === 'WARNING'"
          icon="AlertCircleIcon"
          class="AlertCircleIcon"
          size="64"
          style="color: #ff6c10"
        />
        <feather-icon
          v-if="this.$props.kind.toUpperCase() === 'ERROR'"
          icon="AlertCircleIcon"
          class="AlertCircleIcon"
          size="64"
          style="color: #ff1010"
        />
      </div>
      <h2 class="text-center mt-2">
        {{ this.$props.title }}
      </h2>

      <div
        class="d-flex flex-column align-items-center justify-center-center font-weight-bold"
      >
        <p>
          {{ this.$props.content }}
        </p>
      </div>

      <div
        class="d-flex mt-1"
        style="gap: 16px"
      >
        <b-button
          v-if="!!this.$props.confirmButtonLabel"
          variant="primary-button"
          @click="confirmAction"
        >
          {{ this.$props.confirmButtonLabel }}
        </b-button>

        <b-button
          v-if="!!this.$props.cancelButtonLabel"
          variant="outline-secondary"
          style="color: #ff1010; border-color: #ff1010 !important;"
          @click="cancelAction"
        >
          {{ this.$props.cancelButtonLabel }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped lang="scss">
p {
  font-size: 14px;
  text-align: justify;
  line-height: 21px;
}
</style>
