<template>
    <b-modal
        id="confirm-modal"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon
                id="view-modal"
                :icon="iconModal"
                :class="'text-'+type+ ' mb-2'"
                size="80"
            />
            <div class="text-center">
                <h3>{{titleModal}}</h3>
                <span v-if="pessoaRemover < 1">{{infoTextModal}}</span> 
                <span v-else><span class="pb-1 text-danger">{{pessoaRemover}} pessoas selecionadas.</span> O registro de vacinação será excluído permanentemente, essa ação não poderá ser desfeita.</span>
            </div>
            <div class="d-flex mt-2">
                <b-button :disabled="loading" v-if="cancel" variant="primary-button" class="mr-2" @click="cancelAction">
                    {{cancel}}
                </b-button>
                <b-button :disabled="loading" :variant="'outline-'+type" @click="confirmAction" >
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        {{ action }}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        caseModal: {
            required: true,
            type: String
        },
        infoText: {
            type: String
        },
        pessoaRemover: {
            type: Number,
            required: false,
        }
    },

    data() {
        return {
            loading: false,
            textComplement: '',
            modalTypes: [
                {
                    'type': 'danger',
                    'case': 'remover',
                    'action': 'Sim, remover',
                    'icon': 'AlertCircleIcon',
                    'cancel': 'Cancelar',
                    'title': 'Deseja remover vacinação?',
                    'infoText': 'O registro de vacinação será excluído permanentemente, essa ação não poderá ser desfeita.'
                },
                {
                    'type': 'danger',
                    'case': 'removerMultiplo',
                    'action': 'Sim, remover',
                    'icon': 'AlertCircleIcon',
                    'cancel': 'Cancelar',
                    'title': `Deseja remover vacinação?`,
                    'infoText': ''
                },
                {
                    'type': 'danger',
                    'case': 'selecionar',
                    'action': 'Entendido',
                    'icon': 'XCircleIcon',
                    'title': 'Selecione apenas 1 status',
                    'infoText': 'Não é possível marcar dois status ao mesmo tempo. Desmarque o status previamente selecionado e refaça sua seleção.'
                },
                {
                    'type': 'warning',
                    'case': 'desmarcar',
                    'cancel': 'Cancelar',
                    'action': 'Sim, desmarcar',
                    'icon': 'XCircleIcon',
                    'title': `Tem certeza que deseja desmarcar as pessoas?`,
                    'infoText': ''
                },
                {
                    'type': 'custom-blue',
                    'case': 'marcar',
                    'cancel': 'Não vacinados',
                    'action': 'Vacinados',
                    'icon': '',
                    'title': 'Selecione o status que deseja marcar nesta página:',
                    'infoText': ''
                }
            ],
            type: '',
            case: '',
            action: '',
            infoTextModal: '',
            titleModal: '',
            iconModal: '',
            cancel: false,
            count: 0
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.loading = true;
            if(this.caseModal == 'remover' || this.caseModal == 'removerMultiplo'){
                this.$emit('remove')
            }

            this.$emit('confirmed', modalEvent);
        },
        cancelAction(modalEvent) {
            modalEvent.preventDefault();

            this.$emit('canceled', modalEvent);
        },
    },

    watch: {
        modalShow() {
            this.loading = false;

            this.modalTypes.map(item => {
                if(item.case == this.$props.caseModal) {
                    this.action = item.action;
                    this.case = item.case;
                    this.infoTextModal = this.$props.infoText ? this.$props.infoText : item.infoText;
                    this.type = item.type;
                    this.titleModal = item.title;
                    this.iconModal = item.icon;
                    this.cancel = item.cancel;
                }
            });
        }
    }
}
</script>

<style>

</style>