export default {
    handleErrosColaborador(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.cpf = error.cpf ? error.cpf[0] : '';
        errorForm.nome = error.nome ? error.nome[0] : '';
        errorForm.genero = error.genero ? error.genero[0] : '';
        errorForm.data_nascimento = error.data_nascimento ? error.data_nascimento[0] : '';
        errorForm.setor = error.setor ? error.setor[0] : '';
        errorForm.matricula = error.matricula ? error.matricula[0] : '';
        errorForm.id_categoria = error.id_categoria ? error.id_categoria[0] : '';
        errorForm.nome_mae = error.nome_mae ? error.nome_mae[0] : '';
        errorForm.id_nacionalidade = error.id_nacionalidade ? error.id_nacionalidade[0] : '';
    },
    handleErrosImport(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.arquivo = error.arquivo ? error.arquivo[0] : '';
        errorForm.id_empresa = error.id_empresa ? error.id_empresa[0] : '';
        errorForm.error = '';

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    }
}