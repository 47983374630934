<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
      <b-row :class="one?'shadow':''">
        <b-col v-if="title" xl="12">
            <h2 class="m-2">{{ title }}</h2>
        </b-col>
        <b-col
          v-for="item in data"
          :key="item.icon"
          class="pt-3 pb-3 ml-1"
          :class="multiple? 'shadow':''"
        >
          <b-media no-body>
            <b-media-body class="d-flex justify-content-center align-items-center">
              <div class="p-2">
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="28"
                    :icon="item.icon"
                  />
                </b-avatar>
              </div>
              <div class="p-0 mx-3">
                <h3 class="font-weight-bolder text-center m-0">
                  {{ item.title }}
                </h3>
                <b-card-text class="mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </div>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    oneBlock: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return{
      multiple: false,
      one: false
    }
  },
  mounted() {
    if(this.oneBlock) {
      this.one = true;
    }else {
      this.multiple = true;
    }
  }
}
</script>
<style>
.shadow {
    box-shadow: 0px 0px 15px 5px #f2f3f3;
    border-radius: 0.5rem;
}
</style>
