<template>
  <div class="content-wrapper pb-4">
    <PageHeader
      screen-name="Registro de vacinação"
      :link-items="linkItems"
    />

    <ErroIdInexistenteModal
      :modal-show="modalError.showModal"
      :type-modal="modalError.typeModal"
      :case-modal="modalError.caseModal"
      @confirmed="redirectToSelectEmpresa"
    />

    <div class="card card-content px-2">
      <div
        v-if="canShowImportFlow && !isTutorialView()"
        class="card-top pt-2"
      >
        <h1>{{ title }}</h1>
        <div class="actions-row py-2">
          <b-button
            variant="none"
            class="btn"
            :class="{ 'btn-active': isRegistroView() }"
            @click="() => {showListView()}"
          >
            Registro
          </b-button>

          <b-button
            variant="none"
            class="btn"
            :class="{ 'btn-active': isImportView() }"
            @click="() => {showImportView()}"
          >
            Importação
          </b-button>
        </div>
      </div>
      <ListRegistroVacinacao
        v-if="isRegistroView() && loadScreen"
        :params="getSearch"
      />
      <ImportarVacinados
        v-else-if="isImportView() && loadScreen"
        :params="getSearch"
      />
      <TutorialImportacao v-else-if="isTutorialView()" />
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ListRegistroVacinacao from '@/views/custom-pages/Vacinacao/Pages/ListRegistro/List-registro-vacinacao.vue'
import ImportarVacinados from '@/views/custom-pages/Vacinacao/Pages/Importacao/ImportarVacinados.vue'
import { getUserData } from '@/auth/utils'
import perfis from '@/regras/perfis'
import TutorialImportacao from '@/views/custom-pages/Vacinacao/Pages/TutorialImportacao/TutorialImportacao.vue'
import { VACINACAO_VIEWS } from '@/views/custom-pages/Vacinacao/Helpers/utils'
import ErroIdInexistenteModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'

export default {
  components: {
    TutorialImportacao,
    ImportarVacinados,
    ListRegistroVacinacao,
    ErroIdInexistenteModal,
    PageHeader,
    BButton,
  },
  data() {
    return {
      title: 'Resumo do registro',
      canShowImportFlow: false,
      loadScreen: false,
      getSearch: {
        campanha: {},
        unidade: {},
        empresa: {},
        endereco: {}
      },
      linkItems: [
        {
          name: 'Registro de vacinação',
          active: true,
        },
      ],
      modalError: {
        showModal: false,
        typeModal: 'error',
        caseModal: 'selecaoInvalida',
      },
    }
  },
  mounted() {
    if(!this.$route.params.empresa) {
      this.setModalError();
      return;
    }else{
      this.loadScreen = true
    }
    this.getSearch = this.$route.params
    this.redirectUserByProfileKind()
  },
  updated() {
    this.redirectUserByProfileKind()
  },
  methods: {
    setModalError() {
      this.modalError.showModal = true
    },
    updatePageTitle(title, breadcrumbText) {
      this.title = title
      this.$data.linkItems[0].name = breadcrumbText
    },
    showListView() {
      if (this.getActiveView() !== VACINACAO_VIEWS.REGISTRO) this.redirectTo(VACINACAO_VIEWS.REGISTRO)
      this.updatePageTitle('Resumo do registro', 'Registro de vacinação')
    },
    showImportView() {
      if (this.getActiveView() !== VACINACAO_VIEWS.IMPORTAR) this.redirectTo(VACINACAO_VIEWS.IMPORTAR)
      this.updatePageTitle('Importação de vacinados', 'Importar vacinados')
    },
    showTutorialView() {
      if (this.getActiveView() !== VACINACAO_VIEWS.TUTORIAL) this.redirectTo(VACINACAO_VIEWS.TUTORIAL)
      this.updatePageTitle('Importação de vacinados', 'Tutorial importação')
    },
    getActiveView() {
      return this.$route.query.v ?? null
    },
    isRegistroView() {
      return this.getActiveView() === VACINACAO_VIEWS.REGISTRO
    },
    isImportView() {
      return this.getActiveView() === VACINACAO_VIEWS.IMPORTAR
    },
    isTutorialView() {
      return this.getActiveView() === VACINACAO_VIEWS.TUTORIAL
    },
    redirectTo(view = VACINACAO_VIEWS.REGISTRO) {
      this.$router.push(`resumo-registro-vacinacao?v=${view.toLowerCase()}`)
    },
    redirectUserByProfileKind() {
      const accountKindID = getUserData().perfis[0].id_perfil
      this.canShowImportFlow = accountKindID === perfis.SAUDE_DR
      if (!this.canShowImportFlow) {
        this.showListView()
        return
      }

      const activeView = this.getActiveView()
      if (activeView === VACINACAO_VIEWS.IMPORTAR) this.showImportView()
      if (activeView === VACINACAO_VIEWS.REGISTRO) this.showListView()
      if (activeView === VACINACAO_VIEWS.TUTORIAL) this.showTutorialView()
      if (!activeView) this.showListView()
    },
    redirectToSelectEmpresa() {
      this.$router.push({ name: 'empresa-vacinacao-select' })
    },
  },
}

</script>

<style scoped lang="scss">
.actions-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;

  button {
    padding: .75em 2em;
  }

  :is(.btn-active) {
    background-color: #2772C0;
    color: #FFF
  }
}

</style>
