export default {
    handleErrosVacinacao(errorData, errorForm) {
        let error = errorData.errors;
        
        errorForm.error = '';

        if(typeof error == 'string') {
            errorForm.error = error;
        } 

        if(typeof error == 'object') {
            errorForm.error = error.error.message
        }
    }
}