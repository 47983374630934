<template>
  <section class="tips-card">
    <div class="tip-content">
      <img alt="dica-importacao-vacinados-img" src="@/assets/images/svg/dica-importacao-vacinados.svg"/>

      <div class="tip-text">
        <h4>Dúvidas sobre a planilha?</h4>
        <p>Ficou com dúvidas sobre a planilha? Criamos um tutorial que explica como os itens da planilha devem ser
          preenchidos. Clique no botão ao lado para acessar.</p>
      </div>
    </div>

    <b-button variant="none" class="default-btn" @click="() => {
      this.redirectTo(VACINACAO_VIEWS.TUTORIAL)
    }">
      Acessar tutorial
    </b-button>
  </section>
</template>
<script>
import {BButton} from "bootstrap-vue";
import {VACINACAO_VIEWS} from "@/views/custom-pages/Vacinacao/Helpers/utils";

export default {
  computed: {
    VACINACAO_VIEWS() {
      return VACINACAO_VIEWS
    }
  },
  methods: {
    redirectTo(view = VACINACAO_VIEWS.REGISTRO) {
      this.$router.push('resumo-registro-vacinacao?v=' + view.toLowerCase())
    }
  },
  components: {
    BButton
  },
  props: {
    showTutorialHandler: Function
  }
}
</script>

<style scoped lang="scss">
.tips-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background: rgba(39, 114, 192, 0.12);

  button {
    min-width: max-content;
    margin-right: 4em;
  }

  .tip-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2em;

    img {
      margin-left: 2em;
      margin-top: auto;
    }

    .tip-text {
      display: flex;
      flex-direction: column;

      h4 {
        font-weight: 600;
      }

      p {
        font-size: 1rem;
        max-width: 80%;
      }

      h4, p {
        color: #2772C0;
      }
    }
  }
}


@media(max-width: 425px) {
  .tips-card {
    flex-direction: column;
  }

  .tip-text {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  button {
    margin-right: 0 !important;
  }
}
</style>